import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Navigate, Route, Router, Routes  ,Switch, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Questionnaires from './pages/questionnaires/Questionnaires';

import i18n from './i18n';

import { useSelector } from "react-redux";

function App() {
  
  const theme = useTheme() ;
  useEffect(() => {
    i18n.changeLanguage("ar");
    theme.direction = "rtl" ;
    // if (localStorage.getItem("language") == "ar") {
    //   i18n.changeLanguage("ar");
    //   theme.direction = "rtl" ;
    // }
    // else {
    //   theme.direction = "ltr" ;
    //   i18n.changeLanguage("en");
    // }
  } , [])
  const root = document.documentElement;
  root.style.setProperty('--app-font-family', "'Noto Sans Arabic', sans-serif");
  return (
    <>
      <Routes>
       
        <Route path="/" element=<Questionnaires /> />
        
       
      </Routes>

    </>
    
  );
}

export default App;

































