import { configureStore } from '@reduxjs/toolkit'
import directionSlice from './slices/directionSlice'
import questionerSlice from './slices/questionierSlice'




export const store = configureStore({
  reducer: {
    directionData : directionSlice ,
    questioneirData : questionerSlice , 
 

  },
})